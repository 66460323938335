import { Box, Container, Grid, Typography, Link } from '@mui/material';

const Footer = () => {
  const footerLinks = {
    // Product: [
    //   { name: 'Features', href: '#' },
    //   { name: 'Pricing', href: '#' },
    //   { name: 'Case Studies', href: '/case-studies' },
    //   { name: 'Documentation', href: '#' }
    // ],
    Company: [
      { name: 'About Us', href: `${window.location.origin}/about` },
      { name: 'Contact', href: 'mailto:team@getsiftsy.com' }
    ],
    Legal: [
      { name: 'Privacy Policy', href: `${window.location.origin}/privacy` },
      { name: 'Terms of Service', href: `${window.location.origin}/terms` },
    ]
  };

  return (
    <Box 
      component="footer" 
      sx={{ 
        bgcolor: '#F8F7FF',
        py: { xs: 2, md: 4 },
        px: { xs: 2, md: 4 },
        position: 'relative',
        overflow: 'hidden',
        // mx: 'auto',
        // borderRadius: 4,
        // maxWidth: 1200,
        px: { xs: 2, md: 4 },
        width:"100%"
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={6}>
          <Grid item xs={12} md={4}>
            <Box sx={{ mb: 4 }}>
              <img src="./siftsy_logo.png" style={{width:100}}/>
              {/* <Typography
                sx={{
                  color: '#4D4D4D',
                  fontSize: '14px',
                  maxWidth: '300px'
                }}
              >
                Turn comment sections into actionable campaign insights with our powerful analytics platform.
              </Typography> */}
            </Box>
          </Grid>

          {Object.entries(footerLinks).map(([category, links]) => (
            <Grid item xs={12} sm={6} md={2.5} key={category}>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  color: '#1A1A1A',
                  mb: 2
                }}
              >
                {category}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                {links.map((link) => (
                  <Link
                    key={link.name}
                    href={link.href}
                    sx={{
                      color: '#4D4D4D',
                      textDecoration: 'none',
                      fontSize: '14px',
                      '&:hover': {
                        color: '#6039CF',
                      }
                    }}
                  >
                    {link.name}
                  </Link>
                ))}
              </Box>
            </Grid>
          ))}
        </Grid>

        <Box
          sx={{
            borderTop: '1px solid #E6E0FF',
            mt: 6,
            pt: 4,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: 2
          }}
        >
          <Typography
            sx={{
              color: '#808080',
              fontSize: '14px'
            }}
          >
            © {new Date().getFullYear()} Siftsy. All rights reserved.
          </Typography>
          <Box sx={{ display: 'flex', gap: 3 }}>
            {/* <Link
              href="#"
              sx={{
                color: '#808080',
                fontSize: '14px',
                textDecoration: 'none',
                '&:hover': {
                  color: '#6039CF',
                }
              }}
            >
              Twitter
            </Link> */}
            <Link
              onClick={() => window.open("https://www.linkedin.com/company/siftsy", "_blank")}
              sx={{
                cursor: 'pointer',
                color: '#808080',
                fontSize: '14px',
                textDecoration: 'none',
                '&:hover': {
                  color: '#6039CF',
                }
              }}
            >
              LinkedIn
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer; 