import { Box, Grid, Typography, Button } from '@mui/material';
import { keyframes } from '@mui/system';

const float = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const UploadUI = () => {
  return (  
        <Box
          sx={{
            display:"flex",
            flexDirection:"column",
            // bgcolor: 'white',
            // borderRadius: { xs: '16px', sm: '24px' },
            // boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.08)',
            animation: `${float} 3s ease-in-out infinite 0.5s`,
            overflow: 'hidden',
            zIndex: 1,
          }}
        >
         <img src={`./uploadgraphic.png`} width="100%"/>
    </Box>
  );
};

export default UploadUI; 