import { Box, Grid, Typography, Chip, Divider } from '@mui/material';
import { keyframes } from '@mui/system';

const float = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const FeatureCards = () => {
  const features = [
    {
      title: 'Extract key insights',
      src: `./features1.png`,
      delay: '0s'
    },
    {
      title: 'Search & filter',
      summary: 'Shortcuts',
      src: `./features2.png`,
      delay: '0.2s'
    },
    {
      title: 'Save & export',
      src: `./features3.png`,
      delay: '0.4s'
    }
  ];

  return (
    <Grid container spacing={4} sx={{ position: 'relative', zIndex: 1 }}>
      {features.map((feature, index) => (
        <Grid item xs={12} md={4} key={index}>
          <Box
            sx={{
            //   boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.08)'
              height: '100%',
              animation: `${float} 3s ease-in-out infinite`,
              animationDelay: feature.delay,
            }}
          >
            <Typography align="left" variant="h6" sx={{ mb:1, color:"#4728c4", fontWeight:600 }}>{feature.title}</Typography>
            <Divider sx={{borderColor:"#4728c4",borderWidth:.5, mb:3}}/>
            <img src={feature.src} style={{width:"100%",height:"auto"}}/>
          
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default FeatureCards; 