import { Box, Container, Typography } from '@mui/material';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

const TestimonialSection = () => {
  return (
    <Box sx={{       py: { xs: 2, md: 12 },
    px: { xs: 2, md: 4 },
    position: 'relative',
    overflow: 'hidden',
    mx: 'auto',
    borderRadius: 4,
    maxWidth: 1200,
    px: { xs: 2, md: 4 },}}>
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '32px', md: '48px' },
            textAlign: 'center',
            mb: 8,
            fontFamily: 'Gilroy, sans-serif',
            letterSpacing:-1.8,
            color:"#4728c4"
          }}
        >
            What they're sliding {" "}
            <Typography
              component="span"
              variant="inherit"
              sx={{
                color: '#6039CF',
                fontFamily:"Times New Roman MT Condensed",
                letterSpacing:-1.8,
                fontStyle:"italic",
                fontWeight:600,
              }}
            >
              in the DMs...
            </Typography>
        </Typography>

        <Box 
          sx={{
            bgcolor: 'white',
            p: { xs: 5, md: 8 },
            borderRadius: 3,
            maxWidth: '900px',
            mx: 'auto',
            boxShadow: '0 20px 40px rgba(0, 0, 0, 0.08)',
            textAlign: 'center',
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: '4px',
              background: 'linear-gradient(90deg, #6B46C1 0%, #9F7AEA 100%)',
              borderRadius: '3px 3px 0 0',
            }
          }}
        >
          <FormatQuoteIcon 
            sx={{ 
              fontSize: 60, 
              color: '#9F7AEA',
              opacity: 0.2,
              position: 'absolute',
              top: { xs: 20, md: 40 },
              left: { xs: 20, md: 40 },
              transform: 'rotate(180deg)'
            }} 
          />
          <Typography
            sx={{
              fontSize: { xs: '26px', md: '36px' },
              color: '#2D2D2D',
              mb: 4,
              lineHeight: 1.5,
              fontWeight: 500,
              fontStyle: 'italic',
              fontFamily: 'Georgia, serif',
              maxWidth: '800px',
              mx: 'auto',
              position: 'relative',
              zIndex: 1,
              '&::after': {
                content: '""',
                display: 'block',
                width: '60px',
                height: '3px',
                background: 'linear-gradient(90deg, #6B46C1 0%, #9F7AEA 100%)',
                margin: '24px auto 0',
                borderRadius: '2px',
              }
            }}
          >
            [Siftsy] is incredibly helpful and well put together. Seriously, it's made monitoring our campaigns a breeze.
          </Typography>
          {/* <Box sx={{ mt: 3 }}>
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: 600,
                color: '#4A5568',
                mb: 1
              }}
            >
              Sarah Johnson
            </Typography>
            <Typography
              sx={{
                fontSize: '16px',
                color: '#718096',
              }}
            >
              Marketing Director, TechFlow Inc.
            </Typography>
          </Box> */}
        </Box>
      </Container>
    </Box>
  );
};

export default TestimonialSection; 