import { Box, Container, Typography, Card, CardContent, Button, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import React from 'react';

const CaseStudySection = () => {
  const navigate = useNavigate();
  const sliderRef = React.useRef(null);

  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    appendDots: dots => (
      <Box sx={{ 
        position: 'relative',
        bottom: 0,
        display: { xs: 'flex', md: 'flex' }, 
        alignItems: 'center',
        justifyContent: 'center',
        gap: { xs: 2, md: 2 },
        mt: { xs: 2, md: 4 },
        width: '100%',
        '& .slick-dots': {
          position: 'static',
          display: { xs: 'none !important', md: 'flex !important' },
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
          padding: 0,
          margin: 0,
          width: 'auto',
          maxWidth: '200px',
          margin: '0 auto',
          '& li': {
            margin: 0,
            width: 'auto',
            '& button:before': {
              fontSize: '8px'
            }
          }
        }
      }}>
        <Button sx={{ 
          backgroundColor: 'white',
          boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
          minWidth: { xs: '32px', md: '40px' },
          height: { xs: '32px', md: '40px' },
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 0,
          fontSize: { xs: '14px', md: '20px' },
          '&:hover': {
            backgroundColor: 'white',
          }
        }} onClick={previous}>←</Button>
        {/* <Box sx={{ 
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>{dots}</Box> */}
        <Button sx={{ 
          backgroundColor: 'white',
          boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
          minWidth: { xs: '32px', md: '40px' },
          height: { xs: '32px', md: '40px' },
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 0,
          fontSize: { xs: '14px', md: '20px' },
          '&:hover': {
            backgroundColor: 'white',
          }
        }} onClick={next}>→</Button>
      </Box>
    ),
    nextArrow: <></>,
    prevArrow: <></>,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: true,
          centerMode: true,
          centerPadding: '24px',
        }
      }
    ]
  };

  return (
    <Box
    sx={{
      bgcolor: '#ddd5ff',
      py: { xs: 8, md: 8 },
      px: { xs: 2, md: 4 },
      position: 'relative',
      overflow: 'hidden',
      width: '100vw',
      borderRadius: 4,
      maxWidth: 1200,
    }}
  >
      <Typography
        variant="h2"
        sx={{
          fontSize: { xs: '32px', md: '48px' },
          textAlign: 'center',
          fontFamily: 'Gilroy, sans-serif',
          letterSpacing:-1.8,
          color:"#4728c4",
          
          
        }}
      >
        Siftsy in{"  "}
        <Typography
        variant="h2"
          sx={{
            fontSize: { xs: '32px', md: '48px' },
            display: 'inline-block',
            color: '#6039CF',
            fontFamily:"Times New Roman MT Condensed",
            letterSpacing:-1.8,
            fontStyle:"italic",
            fontWeight:600,
            
          }}>
  Action
          </Typography>
      
      </Typography>

  
           <Box
          sx={{
            px: { xs: 6, md: 6 },
            py: { xs: 4, md: 4 },
          }}
        >
        
          <Typography 
            variant="h4" 
            sx={{ 
              mb: 2.5, 
              fontWeight: 700,
              fontSize: { xs: '24px', md: '32px' },
              background: 'linear-gradient(45deg, #1a1a1a 30%, #404040 90%)',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              color: 'transparent',
              fontFamily: 'Gilroy, sans-serif'
            }}
          >
            Vibes are flying high for Notion
          </Typography>
          <Typography 
            variant="body1" 
            sx={{ 
              color: '#4D4D4D', 
              maxWidth: '800px',
              fontSize: { xs: '16px', md: '18px' },
              lineHeight: 1.7,
              letterSpacing: '0.01em',
              fontFamily: 'Gilroy, sans-serif'
            }}
          >
            <Link 
              href="https://notion.so" 
              target="_blank" 
              rel="noopener noreferrer"
              sx={{
                color: '#2B2B2B',
                fontWeight: 600,
                textDecoration: 'none',
                borderBottom: '2px solid #2B2B2B',
                transition: 'all 0.2s ease',
                '&:hover': {
                  opacity: 0.8,
                  borderBottomColor: 'transparent'
                }
              }}
            >
              Notion
            </Link> and <Link 
              href="https://www.creatormatch.com/" 
              target="_blank" 
              rel="noopener noreferrer"
              sx={{
                color: '#2B2B2B',
                fontWeight: 600,
                textDecoration: 'none',
                borderBottom: '2px solid #2B2B2B',
                transition: 'all 0.2s ease',
                '&:hover': {
                  opacity: 0.8,
                  borderBottomColor: 'transparent'
                }
              }}
            >
              Creator Match
            </Link> used Siftsy to analyze and report on their novel LinkedIn influencer campaign, which garnered a ton of positivity for the brand in the comments.
          </Typography>
          
          <Button
            component="a"
            href="https://www.linkedin.com/posts/siftsy_siftsy-creator-match-x-notion-analysis-activity-7284642870391681027-dZGh"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              mt: 2,
              backgroundColor: 'transparent',
              border: '2px solid #2B2B2B',
              color: '#2B2B2B',
              fontFamily: 'Gilroy, sans-serif',
              fontWeight: 600,
              padding: '8px 24px',
              borderRadius: '8px',
              transition: 'all 0.2s ease',
              '&:hover': {
                backgroundColor: '#2B2B2B',
                color: 'white',
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
              }
            }}
          >
            Read The Report →
          </Button>
  
            </Box>

      <Box sx={{ 
        px: { xs: 2, md: 5 },
        '.slick-slider': {
          '& .slick-list': {
            marginBottom: 2
          },
          '& .slick-dots': {
            display: 'flex !important',
            justifyContent: 'center',
            alignItems: 'center',
            // width: '100%',
            padding: 0,
            margin: 0,
          }
        }
      }}>
        <Slider ref={sliderRef} {...settings}>
          {[
            '/images/CM_CaseStudy/1.png',
            '/images/CM_CaseStudy/2.png',
            '/images/CM_CaseStudy/3.png',
            '/images/CM_CaseStudy/4.png',
            '/images/CM_CaseStudy/5.png',
            '/images/CM_CaseStudy/6.png',
            '/images/CM_CaseStudy/7.png',
            '/images/CM_CaseStudy/8.png',
            '/images/CM_CaseStudy/9.png',
            '/images/CM_CaseStudy/10.png',
            
          ].map((img, index) => (
            <Box key={index} sx={{ px: 0 }}>
              <Box 
                sx={{
                  position: 'relative',
                  width: '100%',
                  height: 'auto',
                  paddingBottom: '125%',
                  overflow: 'hidden',
                  borderRadius: 2,
                  cursor: 'pointer',
                  '&:hover img': {
                    transform: 'scale(1.05)'
                  }
                }}
              >
                <img
                  src={img}
                  alt={`Case study ${index + 1}`}
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    transition: 'transform 0.3s ease'
                  }}
                />
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
  
    </Box>
  );
};

export default CaseStudySection; 