import { ThemeProvider, createTheme } from '@mui/material';
import './App.css';
import './styles/fonts.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Privacy from './Privacy';
import Terms from './Terms';
import LandingPage from './components/LandingPage';
import CaseStudySection from './components/CaseStudySection';
import AboutUsPage from './components/AboutUsPage';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4728c4', // Purple color from the image
    },
    secondary: {
      main: '#b6a1ff', // Light purple for secondary buttons
    },
  },
  typography: {
    fontFamily: 'Gilroy, Arial, sans-serif',
    h1: {
      fontSize: '4rem',
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: '2.5rem',
      fontWeight: 600,
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/privacypolicy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/termsofuse" element={<Terms />} />
          <Route path="/termsofservice" element={<Terms />} />
          <Route path="/case-studies" element={<CaseStudySection />} />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/" element={<LandingPage />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
