import { Box, Typography, Link } from "@mui/material";
import React from "react";



function Footer(){
    return (

<Box sx={{ display: "flex", flexDirection: {xs:"column-reverse", sm:"row"}, gap: 2, justifyContent: "center", alignItems: "center", p: 4, mt:10, pb:4}}>

<Box>
<Typography variant="body1" fontSize={16} fontWeight={500} textAlign="center">
            © {new Date().getFullYear()} Siftsy, LLC
            </Typography>
            </Box>
            <Box sx={{display:"flex", flexDirection:"row", gap:2}}>
            <Box>
            <Typography onClick={() => window.location.href = "/terms"}
             fontWeight={500}sx={{fontSize:16, cursor:"pointer"}} color="primary" underline="hover">
            Terms
            </Typography>
            </Box>
            <Box>
            <Typography onClick={() => window.location.href = "/privacy"} fontWeight={500} sx={{fontSize:16,  cursor:"pointer"}} color="primary" underline="hover">
            Privacy
            </Typography>
            </Box>
            <Box>
            <Typography onClick={() => window.location.href = "mailto: team@siftsy.xyz"}
             fontWeight={500} sx={{fontSize:16,  cursor:"pointer"}} color="primary" underline="hover">
            Contact
            </Typography>
            </Box>
            </Box>
</Box>);
}

export default Footer;