import { Box, Container, Typography, Grid } from '@mui/material';
import Header from './Header';
import Footer from './Footer';

const AboutUsPage = () => {
  const teamMembers = [
    {
      name: 'Brett Dashevsky',
      role: 'Business Dev, Product Design',
      image: './brett.png',
      description: 'Founder of Creator Economy NYC\nTop Voice in Creator Economy/Influencer Marketing',
      email: 'brett@getsiftsy.com'
    },
    {
      name: 'Leon Lyakovetsky',
      role: 'Product, Ops, Technology',
      image: './leon.png',
      description: 'Software Engineer\nCreator/Media Analytics and Tool Expert',
      email: 'leon@getsiftsy.com'
    }
  ];

  return (
    <Box sx={{ 
      bgcolor: '#FFFFFF',
      width: '100%',
      maxWidth: '100vw',
      overflowX: 'hidden',
      position: 'relative',
      fontFamily: 'Gilroy, sans-serif',
      backgroundImage: `
        linear-gradient(to right, rgba(221, 213, 255, 0.2) 2px, transparent 2px),
        linear-gradient(to bottom, rgba(221, 213, 255, 0.2) 2px, transparent 2px)
      `,
      backgroundSize: '60px 60px',
      backgroundPosition: 'center center'
    }}>
      <Header />
      <Container maxWidth="lg" sx={{ py: { xs: 8, md: 12 }, px: { xs: 2, sm: 3, md: 4 } }}>
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: '40px', md: '64px' },
            fontWeight: 700,
            color: '#4728c4',
            mb: 6,
            textAlign: 'center',
            letterSpacing: -2
          }}
        >
          Our team
        </Typography>

        <Box sx={{ mb: 8 }}>
          <Typography
            sx={{
              fontSize: { xs: '18px', md: '24px' },
              color: '#4D4D4D',
              maxWidth: '800px',
              mx: 'auto',
              textAlign: 'center',
              mb: 8,
              fontStyle: 'italic'
            }}
          >
            "Our journey in the creator economy has revealed the untapped potential of comment sections as crucial yet underutilized sources of insight for marketers and brands to truly understand their audiences and campaign/post performance."
          </Typography>

          <Grid container spacing={6} justifyContent="center">
            {teamMembers.map((member) => (
              <Grid item xs={12} sm={6} md={5} key={member.name}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center'
                  }}
                >
                  <Box
                    component="img"
                    src={member.image}
                    alt={member.name}
                    sx={{
                      width: 200,
                      height: 200,
                      borderRadius: '50%',
                      mb: 3,
                      bgcolor: '#4728c4'
                    }}
                  />
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 600,
                      mb: 1
                    }}
                  >
                    {member.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '18px',
                      color: '#4728c4',
                      mb: 2
                    }}
                  >
                    {member.role}
                  </Typography>
                  <Typography
                    sx={{
                      color: '#4D4D4D',
                      whiteSpace: 'pre-line',
                      mb: 2
                    }}
                  >
                    {member.description}
                  </Typography>
                  <Typography
                    sx={{
                      color: '#808080'
                    }}
                  >
                    {member.email}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box sx={{ mt: 12 }}>
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '32px', md: '48px' },
              fontWeight: 700,
              color: '#1A1A1A',
              mb: 4,
              textAlign: 'center'
            }}
          >
            Who we are
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '18px', md: '20px' },
              color: '#4D4D4D',
              maxWidth: '800px',
              mx: 'auto',
              textAlign: 'center',
              lineHeight: 1.6
            }}
          >
            Siftsy is the industry leading comment section analytics platform that's empowering influencer marketing and social media teams to uncover what audiences really 
            <b> think, feel, and say </b> — straight from the comments. We simplify capturing and measuring comment section analytics for any social post, giving you reliable and seamless access to holistic reporting, actionable insights, and campaign trends.
          </Typography>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export default AboutUsPage; 