import { AppBar, Box, Button, Grid } from '@mui/material';
import { useState } from 'react';
import DemoFormModal from './DemoFormModal';

const Header = () => {
  const [demoModalOpen, setDemoModalOpen] = useState(false);

  return (
    <>
      <Grid container justifyContent="center" sx={{ position: 'sticky', top: 16, px: { xs: 1, sm: 2 }, zIndex: 1100 }}>
        <Grid item xs={12}>
          <AppBar
            position="relative"
            color="transparent"
            elevation={0}
            sx={{
              bgcolor: 'white',
              borderRadius: { xs: '12px', sm: '16px' },
              boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.08)',
              maxWidth: 1200,
              mx: 'auto',
              
            }}
          >
            <Grid container sx={{ py: { xs: 1, sm: 1.5 }, px: { xs: 1.5, sm: 2 } }} alignItems="center">
              <Grid item xs>
                <Box
                  component="img"
                  src="/siftsy_logo.png"
                  alt="Siftsy"
                  sx={{
                    height: { xs: '28px', sm: '32px' },
                    width: 'auto',
                  }}
                />
              </Grid>
              <Grid item>
                {/* <Button
                  onClick={() => {
                    window.open('https://app.siftsy.xyz', '_blank');
                  }}
                  variant="contained"
                  sx={{
                    bgcolor: '#6039CF',
                    color: 'white',
                    px: { xs: 2, sm: 3 },
                    py: { xs: 0.75, sm: 1 },
                    fontSize: { xs: '13px', sm: '14px' },
                    fontWeight: 600,
                    '&:hover': {
                      bgcolor: '#4F2FB0',
                    },
                    borderRadius: '8px',
                    textTransform: 'none',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Sign In
                </Button> */}
                <Button
                  onClick={() => setDemoModalOpen(true)}
                  variant="contained"
                  sx={{
                    bgcolor: '#4828c4',
                    color: 'white',
                    px: { xs: 2, sm: 3 },
                    py: { xs: 0.75, sm: 1 },
                    fontSize: { xs: '13px', sm: '14px' },
                    fontWeight: 600,
                    '&:hover': {
                      bgcolor: '#4F2FB0',
                    },
                    borderRadius: '8px',
                    textTransform: 'none',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Request a Demo
                </Button>
              </Grid>
            </Grid>
          </AppBar>
        </Grid>
      </Grid>
      <DemoFormModal 
        open={demoModalOpen}
        onClose={() => setDemoModalOpen(false)}
      />
    </>
  );
};

export default Header; 