import { Modal, Box, IconButton } from '@mui/material';
import { FilloutStandardEmbed } from "@fillout/react";
import CloseIcon from '@mui/icons-material/Close';

const DemoFormModal = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="demo-request-modal"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 0,
          width: '80%',
          maxWidth: 1200,
          height: '80%',
          maxHeight: 1800,
          overflow: 'auto',
          borderRadius: 2,
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey.500',
            zIndex: 1,
          }}
        >
          <CloseIcon />
        </IconButton>
        <FilloutStandardEmbed 
          filloutId="jC2n4LiNsQus"
          onSubmit={() => {
            console.log('Form submitted');
            onClose();
          }}
        />
      </Box>
    </Modal>
  );
};

export default DemoFormModal; 