import { Box } from '@mui/material';
import PepperLogo from './svg/PepperLogo';
import { useEffect, useRef, useState } from 'react';

const TrustedBy = () => {
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current && contentRef.current) {
        const shouldScroll = contentRef.current.scrollWidth / 2 > containerRef.current.clientWidth;
        setShouldAnimate(shouldScroll);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, []);

  return (
    <Box
      ref={containerRef}
      sx={{
        position: 'relative',
        maxWidth: '1200px',
        mx: 'auto',
        overflow: 'hidden',
      }}
    >
      <Box
        ref={contentRef}
        sx={{
          display: 'flex',
          gap: { xs: 2, md: 6 },
          alignItems: 'center',
          px: { xs: 1, md: 4 },
          animation: shouldAnimate ? 'scroll 30s linear infinite' : 'none',
          '@keyframes scroll': {
            '0%': { transform: 'translateX(0)' },
            '100%': { transform: 'translateX(-50%)' }
          },
          '&:hover': {
            animationPlayState: 'paused',
          },
          justifyContent: shouldAnimate ? 'flex-start' : 'center',
        }}
      >
        <Box
          component="img"
          src="/pepper.png"
          alt="Pepper"
          sx={{
            width: 'clamp(80px, 12vw, 180px)',
            height: 'auto',
            transition: 'opacity 0.2s',
            '&:hover': { opacity: 1 },
            flexShrink: 0,
          }}
        />
        <Box
          component="img"
          src="/influencermarketingfactory.png"
          alt="Influencer Marketing Factory"
          sx={{
            width: 'clamp(100px, 15vw, 200px)',
            height: 'auto',
            transition: 'opacity 0.2s',
            '&:hover': { opacity: 1 },
            flexShrink: 0,
          }}
        />
        {/* <Box
          component="img"
          src="/mckinney.png"
          alt="McKinney"
          sx={{
            height: '32px',
            opacity: 0.6,
            transition: 'opacity 0.2s',
            '&:hover': { opacity: 1 },
          }}
        /> */}
        <Box
          component="img"
          src="/sosani.png"
          alt="Sosani"
          sx={{
            width: 'clamp(80px, 12vw, 180px)',
            height: 'auto',
            transition: 'opacity 0.2s',
            '&:hover': { opacity: 1 },
            flexShrink: 0,
          }}
        />
        
        {shouldAnimate && (
          <>
            <Box
              component="img"
              src="/pepper.png"
              alt="Pepper"
              sx={{
                borderRadius: '10px',
                width: 'clamp(80px, 12vw, 180px)',
                height: 'auto',
                transition: 'opacity 0.2s',
                '&:hover': { opacity: 1 },
                flexShrink: 0,
              }}
            />
            <Box
              component="img"
              src="/influencermarketingfactory.png"
              alt="Influencer Marketing Factory"
              sx={{
                borderRadius: '10px',
                width: 'clamp(100px, 15vw, 200px)',
                height: 'auto',
                transition: 'opacity 0.2s',
                '&:hover': { opacity: 1 },
                flexShrink: 0,
              }}
            />
            <Box
              component="img"
              src="/sosani.png"
              alt="Sosani"
              sx={{
                borderRadius: '10px',
                width: 'clamp(80px, 12vw, 180px)',
                height: 'auto',
                transition: 'opacity 0.2s',
                '&:hover': { opacity: 1 },
                flexShrink: 0,
              }}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default TrustedBy; 