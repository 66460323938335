import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import UploadUI from './UploadUI';
import SocialIcons from './svg/SocialIcons';

const UploadSection = () => {
  const platforms = [
    { id: 'tiktok', Icon: SocialIcons.tiktok },
    { id: 'instagram', Icon: SocialIcons.instagram },
    { id: 'youtube', Icon: SocialIcons.youtube },
    { id: 'discord', Icon: SocialIcons.discord },
    { id: 'linkedin', Icon: SocialIcons.linkedin },
  ];

  return (
    <Box
      sx={{
        py: { xs: 8, md: 8 },
        px: { xs: 2, md: 4 },
        bgcolor: 'transparent',
        position: 'relative',
        overflow: 'hidden',
        px: { xs: 2, md: 4 },
        mx: 'auto', // Changed from mx object to just auto
        borderRadius: 4,
        maxWidth: 1200,
      }}
    >
      <Container 
        maxWidth="lg"
        sx={{
          position: 'relative',
        }}
      >
        <Grid container spacing={6} alignItems="center">
          <Grid item xs={12} md={5}>
            <Box sx={{ maxWidth: 480 }}>
              <Typography
                variant="h2"
                component="h2"
                sx={{
                  fontSize: { xs: '32px', md: '48px' },
                  fontWeight: 600,
                  color: '#4728c4',
                  fontFamily:"Gilroy, sans-serif",
                  mb: 3,
                  letterSpacing:-1.8,
                }}
              >
                Link In. {" "}
                <Typography
                  component="span"
                  variant="inherit"
                  sx={{
                    color: '#6039CF',
                    display: 'inline-block',
                    fontFamily:"Times New Roman MT Condensed",
                    letterSpacing:-1.8,
                    fontStyle:"italic",
                    fontWeight:600,
                  }}
                >
                  Report out.
                </Typography>
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '16px', md: '18px' },
                  color: '#4D4D4D',
                  lineHeight: 1.6,
                  mb: 2,
                }}
              >
                Simply upload links from TikTok, Instagram, YouTube, and more. Siftsy makes it seamless.
              </Typography>
              <Box 
                sx={{ 
                  display: 'flex', 
                  gap: 0, 
                  flexWrap: 'wrap',
                  // color: '#808080',
                }}
              >
                <img src={`./platforms.svg`} style={{width:200}}/>
         
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box
              sx={{
                position: 'relative',
                mx: 'auto',
                maxWidth: '100%',
              }}
            >
              <Box sx={{ position: 'relative', zIndex: 1 }}>
                <UploadUI />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default UploadSection; 