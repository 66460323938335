import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import FloatingMetricsCards from './FloatingMetricsCards';

const SentimentSection = () => {
  return (
    <Box
      sx={{
        py: { xs: 6, md: 6 },
        px: { xs: 2, md: 4 },
        mx: 'auto', // Changed from mx object to just auto
        borderRadius: 4,
        maxWidth: 1200,
        bgcolor: '#F8F0FF',
        position: 'relative',
        overflow: 'hidden',
        minHeight: '500px',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Container 
        maxWidth="lg"
        sx={{
          position: 'relative',
        }}
      >
        <Grid container spacing={6} alignItems="center">
          <Grid item xs={12} md={5}>
            <Box sx={{ maxWidth: 480 }}>
              <Typography
                variant="h2"
                component="h2"
                sx={{
                  fontSize: { xs: '32px', md: '48px' },
                  fontWeight: 600,
                  color: '#4728c4',
                  mb: 1,
                  lineHeight: 1.2,
                  letterSpacing:-1.8,
                }}
              >
                Measure audience sentiment{' '}
                <Typography
                  component="span"
                  variant="inherit"
                  sx={{
                    color: '#6039CF',
                    display: 'inline-block',
                    fontFamily:"Times New Roman MT Condensed",
                    letterSpacing:-2,
                    fontStyle:"italic",
                    fontWeight:600,
                  }}
                >
                  instantly.
                </Typography>
                
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '16px', md: '18px' },
                  color: '#4D4D4D',
                  lineHeight: 1.6,
                }}
              >
                Evaluate audience sentiment in minutes with <b>Siftsy Score</b> and sentiment breakdown metrics.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
              <Box 
                sx={{ 
                  position: 'relative', 
                  zIndex: 1, 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center',
                  '@keyframes float': {
                    '0%': {
                      transform: 'translateY(0px)'
                    },
                    '50%': {
                      transform: 'translateY(-10px)'
                    },
                    '100%': {
                      transform: 'translateY(0px)'
                    }
                  },
                  animation: 'float 4s ease-in-out infinite'
                }}
              >
                <img width="100%" src="/Siftsy_Score.png" alt="Sentiment Section" />
              </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default SentimentSection; 