import React from 'react';
import Footer from './Footer';
import { ThemeProvider, Typography, createTheme } from '@mui/material';
import { siftsyTheme } from './siftsytheme';
import SiftsyAppBar from './SiftsyAppBar';
import { Box } from '@mui/system';

const theme = createTheme(siftsyTheme);

//privacy page
function Privacy() {
  return (
    <Box sx={{background:"#f7f8fa"}}>
    <ThemeProvider theme={theme}>
       <SiftsyAppBar />
    <Box sx={{maxWidth:1000, margin:"auto", p:4, textAlign:"left"}}>

      <Typography variant="h4" gutterBottom>
        Privacy Policy
      </Typography>

      {/* Last Updated */}
      <Typography variant="body1" gutterBottom>
        <strong>Last Updated:</strong> 05/24/2024
      </Typography>

      {/* Introduction */}
      <Typography variant="body1" paragraph>
        Welcome to Siftsy. We respect your privacy and want to protect your personal information. This Privacy Policy explains how we collect, use, and share information when you use our website and services.
      </Typography>

      {/* Information We Collect */}
      <Typography variant="h6" gutterBottom>
        Information We Collect
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Personal Information:</strong> This may include your name, email address, and other identifying information that you provide when you sign up for our service.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Usage Data:</strong> We collect data about how you interact with our website and services, including pages visited, time spent, and other behavioral metrics.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Cookies:</strong> Our website uses cookies to enhance your user experience and track behavior within our site.
      </Typography>

      {/* How We Use Information */}
      <Typography variant="h6" gutterBottom>
        How We Use Information
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>To Provide and Improve Our Services:</strong> This may include using information to provide customer support, optimize our product, and for other internal purposes.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Marketing and Promotions:</strong> With your consent, we may use your information to send you news and promotions.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Legal Obligations:</strong> We may use your information as required by law or in response to legal processes.
      </Typography>

      {/* Sharing Your Information */}
      <Typography variant="h6" gutterBottom>
        Sharing Your Information
      </Typography>
      <Typography variant="body1" paragraph>
        We do not sell, trade, or otherwise transfer your personally identifiable information to external parties unless we provide you with advance notice.
      </Typography>

      {/* Data Security */}
      <Typography variant="h6" gutterBottom>
        Data Security
      </Typography>
      <Typography variant="body1" paragraph>
        We employ various security measures to protect your information.
      </Typography>

      {/* Your Choices */}
      <Typography variant="h6" gutterBottom>
        Your Choices
      </Typography>
      <Typography variant="body1" paragraph>
        You have the right to access, correct, or delete your personal information. You also have the right to opt out of receiving promotional communications.
      </Typography>

      {/* Changes to This Policy */}
      <Typography variant="h6" gutterBottom>
        Changes to This Policy
      </Typography>
      <Typography variant="body1" paragraph>
        We may update our Privacy Policy from time to time. Changes will be posted on this page, and your continued use of our services after such changes have been posted will constitute your agreement to the modified Privacy Policy.
      </Typography>

      {/* Contact Us */}
      <Typography variant="h6" gutterBottom>
        Contact Us
      </Typography>
      <Typography variant="body1" paragraph>
        For questions or comments regarding this Privacy Policy, please contact us at <a href="mailto:team@getsiftsy.com">team@getsiftsy.com</a>.
      </Typography>
</Box>
      <Footer/>
   
    </ThemeProvider>
    </Box>
  );
}

export default Privacy;