import { Box, Button, Container, Typography } from '@mui/material';
import DemoFormModal from './DemoFormModal';
import { useState } from 'react';

const CTASection = () => {
  const [isDemoModalOpen, setIsDemoModalOpen] = useState(false);

  const handleOpenDemoModal = () => setIsDemoModalOpen(true);
  const handleCloseDemoModal = () => setIsDemoModalOpen(false);

  return (
    <Box
      sx={{
        bgcolor: "#4828c4",//'#744eff',
        py: { xs: 8, md: 12 },
        px: { xs: 2, md: 4 },
        position: 'relative',
        overflow: 'hidden',
        borderRadius: 4,
        // width: '100%',
        maxWidth: 1200,
        // mx: 'auto',
        width: '100%'
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center'
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '32px', md: '48px' },
              fontWeight: 700,
              color: '#fff',
              mb: 3,
              letterSpacing:-1.8,
              
            }}
          >
             Say bye to the manual grind. 
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '18px', md: '20px' },
              color: '#fff',
              mb: 4,
              maxWidth: '600px',
              fontWeight:600
            }}
          >
        Make wrap reports a breeze with Siftsy's powerful analytics.


          </Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              variant="contained"
              onClick={handleOpenDemoModal}
              sx={{
                bgcolor: '#fff',
                color: '#4728c4',
                px: 4,
                py: 2,
                fontSize: '16px',
                fontWeight: 600,
                '&:hover': {
                  bgcolor: '#fff',
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 12px rgba(71, 40, 196, 0.25)',
                },
                transition: 'all 0.2s ease-in-out',
                borderRadius: '8px',
                textTransform: 'none',
              }}
            >
              Request a Demo
            </Button>
          </Box>
        </Box>
      </Container>
      <DemoFormModal open={isDemoModalOpen} onClose={handleCloseDemoModal} />
    </Box>
  );
};

export default CTASection; 