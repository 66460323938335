import { Box, Button, Container, Typography } from '@mui/material';
import Header from './Header';
import TrustedBy from './TrustedBy';
import SentimentSection from './SentimentSection';
import UploadSection from './UploadSection';
import TableViewSection from './TableViewSection';
import FeaturesSection from './FeaturesSection';
import TestimonialSection from './TestimonialSection';
import Footer from './Footer';
import ScrollToTop from './ScrollToTop';
import FloatingMetricsCards from './FloatingMetricsCards';
import CTASection from './CTASection';
import CaseStudySection from './CaseStudySection';
import DemoFormModal from './DemoFormModal';
import { useState } from 'react';

const LandingPage = () => {
  const [isDemoModalOpen, setIsDemoModalOpen] = useState(false);

  const handleOpenDemoModal = () => setIsDemoModalOpen(true);
  const handleCloseDemoModal = () => setIsDemoModalOpen(false);

  return (
    <Box sx={{ 
      bgcolor: '#FFFFFF', 
      width: '100%',
      // maxWidth: '100vw',
      overflowX: 'hidden',
      position: 'relative',
      scrollBehavior: 'smooth',
      fontFamily: 'Gilroy, sans-serif',
      backgroundImage: `
        linear-gradient(to right, rgba(221, 213, 255, 0.2) 2px, transparent 2px),
        linear-gradient(to bottom, rgba(221, 213, 255, 0.2) 2px, transparent 2px)
      `,
      backgroundSize: '60px 60px',
      backgroundPosition: 'center center',
      gap:3,
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      justifyContent:"center"
    }}>
      <Header />
      <Container 
        maxWidth="lg" 
        // disableGutters={false}
        sx={{ 
          px: { xs: 2, sm: 3, md: 4 },
          mx: 'auto',
          position: 'relative',
          width: '100%',
          boxSizing: 'border-box'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            pt: { xs: 8, sm: 10, md: 12 },
            pb: { xs: 4, sm: 5, md: 6 },
            position: 'relative',
            fontFamily: 'Gilroy, sans-serif',
            backgroundColor: 'transparent'
          }}
        >
          <Typography
            variant="h1"
            component="h1"
            color="primary"
            sx={{
              mb: 2,
              px:4,
              fontSize: { xs: '40px', md: '64px' },
              fontWeight: 600,
              lineHeight: 1.1,
            //   color: '#1A1A1A',
              maxWidth: '800px',
              position: 'relative',
              letterSpacing:-3,
              fontFamily: 'Gilroy, sans-serif'
            }}
          >
            Turn comment sections into{' '}
            <Typography
              component="span"
              variant="inherit"
              sx={{
                background: 'linear-gradient(90deg, #4728c4 0%, #6B4DE6 100%)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                fontFamily: "Times New Roman MT Condensed, serif",
                letterSpacing:-3,
                fontStyle:"italic",
                fontWeight:400,
                display: 'inline-block',
                fontSize: { xs: '40px', md: '64px' },
              }}
              color="#b5a2fa"
            >
              campaign insights.
            </Typography>
          </Typography>
          
          <Typography
            sx={{
              mb: 4,
              px:4,
              fontSize: { xs: '18px', md: '20px' },
              color: '#4D4D4D',
              maxWidth: '700px',
              lineHeight: 1.5,
              fontFamily: 'Gilroy, sans-serif',
              fontWeight: 600
            }}
          >
            Siftsy is the comment section analytics tool that social teams rely on for multi-platform audience sentiment reporting.
          </Typography>

          <Box sx={{ display: 'flex', gap: 2, mb: 8 }}>
            {/* <Button
              variant="contained"
              sx={{
                bgcolor: '#4728c4',
                color: 'white',
                px: 4,
                py: 1.75,
                fontSize: '16px',
                fontWeight: 600,
                '&:hover': {
                  bgcolor: '#3B20A3',
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 12px rgba(71, 40, 196, 0.25)',
                },
                transition: 'all 0.2s ease-in-out',
                borderRadius: '8px',
                textTransform: 'none',
              }}
            >
              Try for Free
            </Button> */}
            <Button
              variant="contained"
              onClick={handleOpenDemoModal}
              sx={{
                bgcolor: '#E6E0FF',
                color: '#6039CF',
                px: 4,
                py: 1.75,
                fontSize: '16px',
                fontWeight: 600,
                '&:hover': {
                  bgcolor: '#D1C7FF',
                  transform: 'translateY(-2px)',
                },
                transition: 'all 0.2s ease-in-out',
                borderRadius: '8px',
                textTransform: 'none',
              }}
            >
              Request a Demo
            </Button>
          </Box>

          <Box>
            <Typography
              sx={{
                color: '#808080',
                fontSize: '14px',
                mb: 3,
                fontFamily: 'Gilroy, sans-serif'
              }}
            >
              Trusted by the forward-looking social teams at:
            </Typography>
            <TrustedBy />
          </Box>
          {/* <FloatingMetricsCards /> */}
        </Box>
                <Box sx={{
                  maxHeight: {xs: 300, sm: 400, md:600},
                  overflow: "hidden",
                  mb: -4,
                  animation: 'popUp 1s cubic-bezier(0.16, 1, 0.3, 1) forwards',
                  animationDelay: '.5s',
                  opacity: 0,
                  '@keyframes popUp': {
                    '0%': {
                      opacity: 0,
                      transform: 'translateY(40px)'
                    },
                    '100%': {
                      opacity: 1,
                      transform: 'translateY(0)'
                    }
                  }
                }}>
                  <img src="/platformhero.png" alt="Siftsy Dashboard" width="100%" />
                </Box>
           

      </Container>
      
      <SentimentSection />
      <UploadSection />
      <TableViewSection />
      <FeaturesSection />
      <CaseStudySection />
      <TestimonialSection />
      <CTASection />
      <Footer />
      <ScrollToTop />
      <DemoFormModal open={isDemoModalOpen} onClose={handleCloseDemoModal} />
    </Box>
  );
};

export default LandingPage; 